import useFcmToken from '#app/hooks/useFcmToken';
import { useIsMenuRoute } from '#app/hooks/useIsMenuRoute';
import { useIsHydrated } from '#app/hooks/useIsHydrated';
import React, { createContext, useContext, ReactNode, useState, useEffect, useCallback } from 'react';
import { useLocalStorage } from '#app/hooks/useLocalStorage';
import { useFetcher, useMatches } from '@remix-run/react';
import { randomUUID } from '#app/utils/misc';


type FirebaseMessagingProviderContextProps = {
  notificationPermissionStatus: NotificationPermission | null
  token: string | null
}
const FirebaseMessagingProviderContext = createContext<FirebaseMessagingProviderContextProps | undefined>(undefined);

export const FirebaseMessagingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const isMenuRoute = useIsMenuRoute();
    const matches = useMatches();
    const isLogin = matches.findIndex(match => {
      return match.pathname.includes('/login')
    }) !== -1;

    const fetcher = useFetcher({
      key: 'update-fcm-token',
    });
    
    const [notificationPermission, setNotificationPermission] = useState('');
    const shouldLoadToken = notificationPermission === 'granted' && !isMenuRoute && !isLogin;
    const { token, notificationPermissionStatus } = useFcmToken({shouldLoadToken});

    const isHydrated = useIsHydrated();
    useEffect(() => {
      if(("Notification" in window)) {
        setNotificationPermission(Notification.permission)
      }
    }, [isHydrated])
    const [fcmToken, setFcmToken] = useLocalStorage<string | null>('fcmToken', null);
    const [deviceId, setDeviceId] = useLocalStorage<string | null>('deviceId', null);

    const registerDeviceId = useCallback(() => {
      if(!shouldLoadToken) {
        return {}
      }
      if(!isHydrated) {
        return {
          id: null,
          status: 'loading'
        }
      }

      if(deviceId) {
        return {
          id:deviceId,
          status: 'success'
        };
      }


      const newDeviceId = randomUUID();
      setDeviceId(newDeviceId);

      return {
        id: newDeviceId,
        status: 'success'
      };

    }, [isHydrated, deviceId, shouldLoadToken])
    
    const deviceRegister = registerDeviceId();

    useEffect(() => {
      const tokenHasChanged = token && fcmToken !== token;
      
      if(isHydrated && shouldLoadToken && tokenHasChanged && deviceId && deviceRegister.status === 'success' && deviceRegister.id) {
        setFcmToken(token)
        
        fetcher.submit({
          deviceId: deviceRegister.id,
            token
        }, {
          action: '/resources/fcm-token',
          method: 'POST',
          encType: 'application/json',
          navigate: false
        })
      }
      
    }, [isHydrated, token, fcmToken, deviceRegister, shouldLoadToken])
    const value = {
      notificationPermissionStatus,
      token
    }
    return <FirebaseMessagingProviderContext.Provider value={value}>{children}</FirebaseMessagingProviderContext.Provider>;
  };


  export const useFirebaseMessaging = (): FirebaseMessagingProviderContextProps => {
    const context = useContext(FirebaseMessagingProviderContext);
    if (!context) {
      throw new Error("useFirebaseMessaging deve ser usado dentro de um FirebaseMessagingProvider");
    }
    return context;
  };