// @ts-nocheck
import { useEffect, useRef } from "react";

export default function useLoadFacebookScript({
	renderScript,
	pixelID
}: {pixelID?: string | null; renderScript: boolean;}) {
	const loadedFb = useRef(false);
  useEffect(() => {

		if(!loadedFb.current && pixelID && renderScript) {
			// @ts-expect-error
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
			n.callMethod.apply(n,arguments):n.queue.push(arguments)};
			if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
			n.queue=[];t=b.createElement(e);t.async=!0;
			t.src=v;s=b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t,s)}(window, document,'script',
			'https://connect.facebook.net/en_US/fbevents.js');
			window.fbq = fbq;
			fbq('init', pixelID);
			fbq('track', 'PageView');

			loadedFb.current = true;
		}
		 
	}, [pixelID, renderScript])
}