import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useMatches,
	useRouteError,
} from '@remix-run/react'
import faviconAssetUrl from './assets/favicon.svg'
import type {
	HeadersFunction,
	LinksFunction,
	LoaderFunctionArgs,
	MetaFunction,
} from '@remix-run/node'
import { json } from '@remix-run/node'
import tailwindStylesheetUrl from '#app/styles/tailwind.css?url'
import globalStylesheetUrl from '#app/styles/global.css?url'
import sonnerStyles from '#app/styles/sonner.css?url'
import reactCropStylesheetUrl from 'react-image-crop/dist/ReactCrop.css?url'
import Navbar from './components/Navbar'
import { GeneralErrorBoundary } from './components/error-boundary'
import { makeTimings, time } from './utils/timing.server'
import { getEnv } from './utils/env.server'
import { honeypot } from './utils/honeypot.server'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { csrf } from './utils/csrf.server'
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react'
import { Toaster } from './components/ui/sonner'

import { getToast } from './utils/toast.server'
import { combineHeaders } from './utils/misc'
import { getUserSession, logout } from './utils/auth.server'
import { prisma } from './utils/db.server'
import { getUserPermissions } from './utils/permissions.server'

import useLoadFacebookScript from './hooks/useLoadFacebookScript'
import { FirebaseMessagingProvider } from './components/Firebase/FirebaseMessagingProvider'
import { useIsMenuRoute } from './hooks/useIsMenuRoute'

export const links: LinksFunction = () => [
	{ rel: 'icon', type: 'image/svg+xml', href: faviconAssetUrl },
	{ rel: 'stylesheet', href: tailwindStylesheetUrl },
	{ rel: 'stylesheet', href: globalStylesheetUrl },
	{ rel: 'stylesheet', href: reactCropStylesheetUrl },
	{ rel: 'stylesheet', href: sonnerStyles },
]

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data?.menuCompanyName ? data?.menuCompanyName : 'Colmeia PRO' },
		{ name: 'description', content: 'Bem vinda ao Colmeia PRO' },
	]
}

export async function loader({ request, params }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	let menuCompanyName = null
	let companyId = null;
	if (params.companySlug) {
		const company = await prisma.organization.findFirst({
			select: {
				id: true,
				name: true,
			},
			where: {
				slug: params.companySlug,
			},
		})
		companyId = company?.id;
		menuCompanyName = company?.name
	}

	const userSession = await time(() => getUserSession(request), {
		timings,
		type: 'getUserSession',
		desc: 'getUserSession in root',
	})

	const userId = userSession?.user.id
	const organizationId = userSession?.organizationId

	const permissions = await getUserPermissions(
		userId ?? '',
		organizationId ?? '',
	)

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							name: true,
							username: true,
							image: { select: { id: true } },
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}

	const organizationTrackingConfig = companyId ? await prisma.organizationTrackingConfig.findFirst({
		select: {
			facebookPixelId: true,
			googleAnalyticsId: true
		},
		where: {
			organizationId: companyId
		}
	}) : null;
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()
	const [csrfToken, csrfCookieHeader] = await csrf.commitToken(request)

	return json(
		{
			ENV: getEnv(),
			honeyProps,
			csrfToken,
			toast,
			user: { ...user, permissions },
			organizationId,
			menuCompanyName,
			organizationTrackingConfig
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
				csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

export function Layout({ children }: { children: React.ReactNode }) {

	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body>			
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	)
}

function App() {
	return <Outlet />
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()

	const facebookPixelId = data.organizationTrackingConfig?.facebookPixelId;
	const googleAnalyticsId = data.organizationTrackingConfig?.googleAnalyticsId;

	const isOrganizationMenuRoute = useIsMenuRoute()
	const shouldRenderMenuTrackingElements = process.env.NODE_ENV === "production" && isOrganizationMenuRoute;

	useLoadFacebookScript({
		renderScript: shouldRenderMenuTrackingElements,
		pixelID: data.organizationTrackingConfig?.facebookPixelId
	})

	return (
		<AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				{(() => {
					if(shouldRenderMenuTrackingElements) {
							return <>
								{facebookPixelId ? <>
									<noscript><img style={{display: 'none', height: '1px', width: '1px'}} alt="fb-pix" src={`https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`}
									/></noscript>
								</> : null}
								{googleAnalyticsId? <>
									<script
										async
										src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
										/>
									<script
										async
										id="gtag-init"
										dangerouslySetInnerHTML={{
											__html: `
											window.dataLayer = window.dataLayer || [];
											function gtag(){dataLayer.push(arguments);}
											gtag('js', new Date());

											gtag('config', '${googleAnalyticsId}', {
											page_path: window.location.pathname,
											});
										`,
										}}
									/>
								</>: null}			
							</>
					}

					return null;
				})()}
				<FirebaseMessagingProvider>
					<App />
				</FirebaseMessagingProvider>
				<Toaster toast={data.toast} />
			</HoneypotProvider>
		</AuthenticityTokenProvider>
	)
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
	const error = useRouteError()
	captureRemixErrorBoundaryError(error)

	return <GeneralErrorBoundary />
}
