import { getApp, getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

//TODO: Convert into environment Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4_s-mjK8vUX5poQc3zXWkxpGsKsNJWHI",
  authDomain: "colmeia-pro.firebaseapp.com",
  projectId: "colmeia-pro",
  storageBucket: "colmeia-pro.firebasestorage.app",
  messagingSenderId: "829759032702",
  appId: "1:829759032702:web:272f6b21374eebbf03f72f",
  measurementId: "G-1EYGJK4YJN"
};

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

const messaging = async () => {
  const supported = await isSupported();
  return supported ? getMessaging(app) : null;
};

export const fetchToken = async () => {
  try {
    const fcmMessaging = await messaging();
    if (fcmMessaging) {
      const token = await getToken(fcmMessaging, {
        // TODO: convert into env
        vapidKey: 'BMXEbfda-wvBndSirkLHcNtdIklKrpnqTJt8w2cx8UsNyIqNGDW3g_WdMn4QaMle4azWl_tM3u7xvMBnfZc8Et8',
      });
      return token;
    }
    return null;
  } catch (err) {
    console.error("An error occurred while fetching the token:", err);
    return null;
  }
};

export { app, messaging };