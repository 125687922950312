import { useMatches } from "@remix-run/react";

export function useIsMenuRoute() {
    const matches = useMatches();

	const isOrganizationMenuRoute = matches.findIndex((match) => {
		return match.id.startsWith('routes/$companySlug+/($menuSlug)+/') || match.id === "routes/orders.$orderId";
	}) !== -1;

    return isOrganizationMenuRoute;
}